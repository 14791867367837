import React, { useCallback, useMemo } from 'react';

import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';

import { DashboardTasksResultsListTasks } from './DashboardTasksResultsList.types';
import { DashboardTasksResultsListItemFileItem } from '../DashboardTasksResultsListItem/components/DashboardTasksResultsListItemFile';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useDownloadTypeFile } from '../../../../s3Multipart/hooks/useDownloadTypeFile';

import { DashboardTasksResultsListItem } from '../DashboardTasksResultsListItem';

import { FileAttachmentItemImageVersions } from '../../../../../helpers/ImageHelper';
import { LightboxWrapperPermissions } from '../../../../../helpers/LightboxWrapper/lightboxWrapperConstants';
import {
  LightboxWrapper,
  OnLightboxImageEditSubmit,
  useLightboxWrapper,
  LightboxThumbnails,
  OnLightboxImageDownload
} from '../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../utils/Files';

interface DashboardTasksResultsListProps {
  tasks: DashboardTasksResultsListTasks;
  onImageEditSubmit?: OnLightboxImageEditSubmit;
}

function DashboardTasksResultsList({
  tasks,
  onImageEditSubmit
}: DashboardTasksResultsListProps) {
  const currentUser = useCurrentUser();

  const itemResultsFileAttachments = useMemo<
    DashboardTasksResultsListItemFileItem[]
  >(() => {
    return filter(
      flatMap(tasks, (task) => task.resultFileAttachments),
      (task) => Files.isImage(task?.file)
    );
  }, [tasks]);

  const lightboxThumbnails = useMemo<LightboxThumbnails>(() => {
    return itemResultsFileAttachments.map((attachment) =>
      Files.urlFromFile(
        attachment.file,
        FileAttachmentItemImageVersions.FullScreenThumb1000x850
      )
    );
  }, [itemResultsFileAttachments]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc,
    mainSrcThumbnail,
    prevSrcThumbnail,
    nextSrcThumbnail
  } = useLightboxWrapper({
    items: itemResultsFileAttachments,
    thumbnails: currentUser.hasPermissions(
      LightboxWrapperPermissions.READ_LIGHTBOX_PLACEHOLDER_THUMBNAILS
    )
      ? lightboxThumbnails
      : null
  });

  const { onDownload } = useDownloadTypeFile({});

  const handleDownload = useCallback<OnLightboxImageDownload>(
    (uuid) => {
      onDownload(uuid, 'fileAttachments');
    },
    [onDownload]
  );

  return (
    <div>
      <div className="overflow-x-auto -mx-1">
        <div className="flex gap-1 p-1">
          {tasks.map((task) => (
            <DashboardTasksResultsListItem
              key={task.uuid}
              taskUuid={task.uuid}
              taskNanoId={task.nanoId}
              taskName={task.name}
              resultsUploadedAt={task.resultsUploadedAt}
              projectNanoId={task.project?.nanoId}
              projectName={task.project?.name}
              resultsFileAttachments={task.resultFileAttachments || []}
              supportMark={task.supportMark}
              taskStatus={task.status}
              taskState={task.state}
              taskSourceFilesCount={task.sourceFilesCount}
              handleLightboxOpenOnSlide={handleLightboxOpenOnSlide}
            />
          ))}

          <LightboxWrapper
            handleLightboxClose={handleLightboxClose}
            handleLightboxNext={handleLightboxNext}
            handleLightboxOpen={handleLightboxOpen}
            handleLightboxPrev={handleLightboxPrev}
            index={index}
            imagesCount={imagesCount}
            imageItem={imageItem}
            lightBoxOpened={lightBoxOpened}
            mainSrc={mainSrc}
            nextSrc={nextSrc}
            prevSrc={prevSrc}
            mainSrcThumbnail={mainSrcThumbnail}
            nextSrcThumbnail={nextSrcThumbnail}
            prevSrcThumbnail={prevSrcThumbnail}
            withFullScreenButton
            withBaseImageTitle
            onImageEditSubmit={onImageEditSubmit}
            onImageDownload={handleDownload}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardTasksResultsList;
